import React from 'react';
import { useState, useEffect } from 'react';


function Hourglass() {
  
  const baseUrl = 'https://wtcui.ca/api'

  let [categories, setCategories] = useState([]);
  const [status, setStatus] = useState([]);

  async function onLoad() {
    const res0 = await fetch(baseUrl + '/login',
    {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({
        parcel: localStorage.password
      })
    })

    // get Status
    const resStatus = await fetch(baseUrl + '/loadStatus',
    {
        method: 'GET',
    }).then((response) => response.json());

    //console.log(resStatus.data)
    setStatus(resStatus.data)

    // get Categories
    const resCategories = await fetch(baseUrl + '/loadCategories',
    {
        method: 'GET',
    }).then((response) => response.json());

    //console.log(resCategories.data)
    setCategories(resCategories.data)
  }
  useEffect(() => {
      let ignore = false;
      if (!ignore) {
        onLoad()
      }
      return () => { ignore = true; }
  },[]);

  async function onSaveStatus(statusUpdate, activeStatus) {
    const res = await fetch(baseUrl + '/saveStatus',
    {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify({
            parcel: {
              statusUpdate : statusUpdate,
              activeStatus : activeStatus,
              currentPassword: localStorage.password
            }
        })
    })
    console.log("save", status)
  }
  async function onSaveCategories(updatedCategories) {
    const res = await fetch(baseUrl + '/saveCategories',
    {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify({
            parcel: {
              updatedCategories : updatedCategories,
              currentPassword: localStorage.password
            }
        })
    })
  }

  const [showChangeStatusModel, setShowChangeStatusModel] = useState(false);
  const [showCreateCategoryModel, setShowCreateCategoryModel] = useState(false);
  const [showRemoveCategoryModel, setShowRemoveCategoryModel] = useState(false);

  function ChangeStatusModel() {

    const [supercategory, setSupercategory] = useState("");
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");

    function handleSubmit(e) {
      e.preventDefault();

      if (category === "") {
        onUpdateStatus(categories.find(element => element.name === supercategory), description);
      } else {
        onUpdateStatus(categories.find(element => element.name === supercategory).subcategories.find(element => element.name === category), description)
      }
  
      setShowChangeStatusModel(false);
    }

    return(
      <div className="border-2 w-100 bg-white rounded-lg shadow-2xl">
        <header className="h-10 px-4 py-2 flex justify-between items-center bg-gray-100">
          <span className="text-gray-700 pr-40">
            Change Status:
          </span>

          <button
              type="button" //to remove annoying console message
              onClick={() => setShowChangeStatusModel(false)}
          >
              <span className="text-gray-400">
                  X
              </span>
          </button>
        </header>

        <div className="p-3">
          <div className="grid grid-cols-1/5 items-end gap-y-5">
            <span className="text-gray-400">
                Name:
            </span>
            <select
              value = {supercategory}
              className="pt-0 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
              onChange = {(e) => setSupercategory(e.target.value)}
            >
              <option value="" disabled selected>
                Select
              </option>
              {categories.map((category, index) => (
                <option key={index} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
            <span className="text-gray-400">
                Subcategory:
            </span>
            <select
              value={category}
              className="pt-0 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">
                N/A
              </option>
              {categories.find(element => element.name === supercategory) ? (
                categories.find(element => element.name === supercategory).subcategories.map((subcategory, index) => (
                  <option key={index} value={subcategory.name}>
                    {subcategory.name}
                  </option>
                ))
              ) : (null)}
            </select>
            <span className="pb-20 text-gray-400">
                Desc:
            </span>
            <textarea
                type="text"
                name="description"
                placeholder="Add a description"
                value={description}
                required
                className="h-20 text-gray-600 w-full border-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
        </div>
        <footer className="flex justify-between border-t p-3 mt-1">                    
          <div></div> {/*empty div for alignment*/}
          <button
              type="submit"
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 w-20 py-2 rounded text-white"
          >
              Save
          </button>
        </footer>
      </div>
    )
  }

  function CreateCategoryModel() {

    const [name, setName] = useState("")
    const [supercategory, setSupercategory] = useState("")

    function handleSubmit(e) {
      e.preventDefault();

      //worry about flags later: inside, alone, work, NSFW
      const newCategory = {
          name,
          flags : [],
          supercategory,
          subcategories : []
      };

      let updatedCategories = categories.map(item => item);

      if (supercategory === "") {
        updatedCategories.push(newCategory);
      } else {
        updatedCategories.find(element => element.name == supercategory).subcategories.push(newCategory);
      }

      setCategories(updatedCategories);
      onSaveCategories(updatedCategories)
  
      setShowCreateCategoryModel(false);
    }

    return(
      <div className="border-2 w-100 bg-white rounded-lg shadow-2xl">
        <header className="h-10 px-4 py-2 flex justify-between items-center bg-gray-100">
          <span className="text-gray-700 pr-40">
            Add Category:
          </span>

          <button
              type="button" //to remove annoying console message
              onClick={() => setShowCreateCategoryModel(false)}
          >
              <span className="text-gray-400">
                  X
              </span>
          </button>
        </header>

        <div className="p-3">
          <div className="grid grid-cols-1/5 items-end gap-y-5">
              <span className="text-gray-400">
                  Name:
              </span>
              <input
                  type="text"
                  name="title"
                  placeholder="Add name"
                  value={name}
                  required
                  className="pt-0 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                  onChange={(e) => setName(e.target.value)}
              />
              <span className="text-gray-400">
                  Parent:
              </span>
              <select
                value = {supercategory}
                className="pt-0 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                onChange = {(e) => setSupercategory(e.target.value)}
              >
                <option value=""> N/A</option>
                {categories.map((category, index) => (
                  <option key={index} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
          </div>
        </div>
        <footer className="flex justify-between border-t p-3 mt-1">                    
          <div></div> {/*empty div for alignment*/}
          <button
              type="submit"
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 w-20 py-2 rounded text-white"
          >
              Save
          </button>
        </footer>
      </div>
    )
  }


  function RemoveCategoryModel() {

    const [supercategory, setSupercategory] = useState("");
    const [category, setCategory] = useState("");

    function handleSubmit(e) {
      e.preventDefault();

      let updatedCategories = categories.map(item => item);

      if (status.includes(category)) {
        onUpdateStatus(updatedCategories.find(element => element.name === supercategory).subcategories.find(element => element.name === category), "");
      }

      updatedCategories.find(element => element.name === supercategory).subcategories = updatedCategories.find(element => element.name === supercategory).subcategories.filter(element => element.name !== category);

      setCategories(updatedCategories);
      onSaveCategories(updatedCategories)
  
      setShowRemoveCategoryModel(false);
    }

    return(
      <div className="border-2 w-100 bg-white rounded-lg shadow-2xl">
        <header className="h-10 px-4 py-2 flex justify-between items-center bg-gray-100">
          <span className="text-gray-700 pr-40">
            Remove Category:
          </span>

          <button
              type="button" //to remove annoying console message
              onClick={() => setShowRemoveCategoryModel(false)}
          >
              <span className="text-gray-400">
                  X
              </span>
          </button>
        </header>

        <div className="p-3">
          <div className="grid grid-cols-1/5 items-end gap-y-5">
            <span className="text-gray-400">
                Name:
            </span>
            <select
              value = {supercategory}
              className="pt-0 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
              onChange = {(e) => setSupercategory(e.target.value)}
            >
              <option value="" disabled selected>
                Select
              </option>
              {categories.map((category, index) => (
                <option key={index} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
            <span className="text-gray-400">
                Subcategory:
            </span>
            <select
              value={category}
              className="pt-0 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="" disabled selected>
                Select
              </option>
              {categories.find(element => element.name === supercategory) ? (
                categories.find(element => element.name === supercategory).subcategories.map((subcategory, index) => (
                  <option key={index} value={subcategory.name}>
                    {subcategory.name}
                  </option>
                ))
              ) : (null)}
            </select>

          </div>
        </div>
        <footer className="flex justify-between border-t p-3 mt-1">                    
          <div></div> {/*empty div for alignment*/}
          <button
              type="submit"
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 w-20 py-2 rounded text-white"
          >
              Save
          </button>
        </footer>
      </div>
    )
  }

  const onUpdateStatus = (category, description) => {
    let updatedStatus = status.map(item => item);
    const now = new Date();
    const packet = [];

    // remove category if it is on, else add it if off (and resp. with supercategory)
    if (updatedStatus.indexOf(category.name) !== -1) {

      updatedStatus = updatedStatus.filter(item => item !== category.name);
      packet.push({name: category.name, status:false, time:now.getTime(), description:description});
      
      if (updatedStatus.indexOf(category.name) !== -1) {

        updatedStatus = updatedStatus.filter(item => item !== category.supercategory);
        packet.push({name: category.supercategory, status:false, time:now.getTime(), description:description});
      }

    } else {
      updatedStatus.push(category.name);
      packet.push({name: category.name, status:true, time:now.getTime(), description:description});

      if (
        category.supercategory !== ""
        && updatedStatus.indexOf(category.supercategory) === -1
      ) {
        updatedStatus.push(category.supercategory);
        packet.push({name: category.supercategory, status:true, time:now.getTime(), description:description});
      }
    }
    setStatus(updatedStatus);

    console.log("status", updatedStatus);
    console.log("packet", packet);

    onSaveStatus(packet, updatedStatus);
  }

  return (
    <div className='w-full h-full flex items-center justify-center font-serif bg-sky-800'>
      <div className='h-screen overflow-scroll scrollbar-thin w-full md:w-1/2 pt-8 px-6 bg-gray-100'>
        <div>
          <button
            onClick={() => {
              setShowChangeStatusModel(true)
            }}
            className="pr-5 text-blue-500"
          >
          Change Status
          </button>
          <button
            onClick={() => {
              setShowCreateCategoryModel(true)
            }}
            className="pr-5 text-blue-600"
          >
          Add Category
          </button>
          <button
            onClick={() => {
              setShowRemoveCategoryModel(true)
            }}
            className="pr-5 text-red-600"
          >
          Remove Category
          </button>
        </div>

        <div>
          {categories.map((category, idx) => (
            <div>
              <button
                key={idx}
                onClick={() => {
                  onUpdateStatus(category, "")
                }}
                className={`mt-5 ${status.includes(category.name) ? 'bg-blue-100' : ''}`}
              >
                <span>{category.name}</span>
              </button>
              <div>
              {category.subcategories.map((subcategory, idxx) => (
                <button
                  key={idxx}
                  onClick={() => {
                    onUpdateStatus(subcategory, "")
                  }}
                  className={`px-2 ${status.includes(subcategory.name) ? 'bg-blue-100' : ''}`}
                >
                  {subcategory.name}
                </button>
              ))}
              </div>
            </div>
          ))}
        </div>

        <React.Fragment>
          {showCreateCategoryModel && <CreateCategoryModel />}
          {showRemoveCategoryModel && <RemoveCategoryModel />}
          {showChangeStatusModel && <ChangeStatusModel />}
        </React.Fragment>

      </div>
    </div>
  );
}


export default Hourglass;
