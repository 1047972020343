import React, { useContext, useState } from 'react'
import dayjs from 'dayjs';

import GlobalContext from '../context/globalcontext';
import { strToMins } from '../util';

import DuplicatorCalendar from './duplicatorcalendar';

export default function EventModel(){

    const { setShowEventModel, selectedDay, dispatchCalEvent, selectedEvent, savedCategories } = useContext(GlobalContext);

    const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "");
    const [description, setDescription] = useState(selectedEvent ? selectedEvent.description : "");
    const [startTime, setStartTime] = useState(selectedEvent ? selectedEvent.startTime : "00:00");
    const [endTime, setEndTime] = useState(selectedEvent ? selectedEvent.endTime : "00:00");
    const [selectedCategory, setSelectedCategory] = useState(
        selectedEvent
            ? {title: selectedEvent.category.title, id: selectedEvent.category.id}
            : {title: "Unclassified", id: 0}
    )

    const [showEventCopier, setShowEventCopier] = useState(false);
    const [duplicateDays, setDuplicateDays] = useState([]);
    const [duplicatorCurrentMonthIdx, setDuplicatorCurrentMonthIdx] = useState(dayjs().month());

    function handleSubmit(e) {
        e.preventDefault();

        const calendarEvent = {
            title,
            description,
            startTime,
            endTime: strToMins(startTime) < strToMins(endTime) ? endTime : startTime,
            category: selectedCategory,
            day: selectedDay.valueOf(),
            id : selectedEvent ? selectedEvent.id : Date.now(),
        };
        if (selectedEvent) {
            dispatchCalEvent({type: "update", payload: calendarEvent});
        } else {
            dispatchCalEvent({type: "push", payload: calendarEvent});
        }
    
        setShowEventModel(false);
    }

    function handleDuplicate(e) {
        e.preventDefault();
        
        const duplicateEvents = duplicateDays.map((duplicateDay, idx) => ({
            title,
            description,
            startTime,
            endTime: strToMins(startTime) < strToMins(endTime) ? endTime : startTime,
            category: selectedCategory,
            day: duplicateDay.valueOf(),
            id: Date.now() + idx,
        }));
        
        duplicateEvents.map((duplicateEvent) => dispatchCalEvent({type: "push", payload: duplicateEvent}));

        setShowEventCopier(false);
    }

    function EventCopier(){
        return(
            <div className="fixed left-2/3 top-40 w-[250px] bg-white rounded-lg shadow-2xl">
                <header className="h-10 px-4 py-2 flex justify-between items-center bg-gray-100">
                <span className="text-gray-400">
                    Copy Event
                </span>
                <button
                    type="button" //to remove annoying console message
                    onClick={() => setShowEventCopier(false)}
                >
                    <span className="text-gray-400">
                        X
                    </span>
                </button>
                </header>
                <div className="h-fit px-5 pb-5">
                    <DuplicatorCalendar
                        duplicateDays={duplicateDays}
                        setDuplicateDays={setDuplicateDays}
                        currentMonthIdx={duplicatorCurrentMonthIdx}
                        setCurrentMonthIdx={setDuplicatorCurrentMonthIdx}
                    />
                    <button
                        className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded text-white"
                        onClick={handleDuplicate}
                    >
                        Duplicate
                    </button>
                </div>
            </div>
        );
    }

    return(
        <div>
            <div className="h-full w-full fixed left-0 top-40 flex justify-center items-start">
                <form className="bg-white rounded-lg shadow-2xl w-1/3">
                    <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
                        <span className="text-gray-700">
                            {!selectedEvent && (
                                <span>Create Event: </span>
                            )}
                            {selectedEvent && (
                                <span>Modify Event: </span>
                            )}
                        </span>
                        <div>
                            {selectedEvent && (
                                <span
                                    onClick={() => {
                                        setShowEventCopier(true);
                                    }}
                                    className="pr-5 text-gray-400 cursor-pointer"
                                >
                                    Copy
                                </span>
                            )}
                            <button
                                type="button" //to remove annoying console message
                                onClick={() => setShowEventModel(false)}
                            >
                                <span className="text-gray-400">
                                    X
                                </span>
                            </button>
                        </div>

                    </header>
                    <div className="p-3">
                        <div className="grid grid-cols-1/5 items-end gap-y-5">
                            <span className="text-gray-400">
                                Title:
                            </span>
                            <input
                                type="text"
                                name="title"
                                placeholder="Add title"
                                value={title}
                                required
                                className="pt-0 border-0 text-gray-600 text-xl pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <span className="text-gray-400">
                                Date:
                            </span>
                            <p>{selectedDay.format("dddd, MMMM DD")}</p>
                            <span className="text-gray-400">
                                Time:
                            </span>
                            <div>
                                <input
                                    type="time"
                                    name="startTime"
                                    placeholder="Start time"
                                    value={startTime}
                                    className="mr-4 text-gray-600 border-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500 w-1/3 "
                                    onChange={(e) => setStartTime(e.target.value)}
                                />
                                <input
                                    type="time"
                                    name="endTime"
                                    placeholder="End time"
                                    value={endTime}
                                    className="text-gray-600 border-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500 w-1/3 "
                                    onChange={(e) => setEndTime(e.target.value)}
                                />
                            </div>
                            <span className="pb-20 text-gray-400">
                                Desc:
                            </span>
                            <textarea
                                type="text"
                                name="description"
                                placeholder="Add a description"
                                value={description}
                                required
                                className="h-20 text-gray-600 w-full border-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                            <span className="text-gray-400">
                                Tag:
                            </span>
                            <div className=""> {/*figure out overfull scroll*/}
                                {savedCategories.map((category, idx) => (
                                    <div key={idx} className="flex justify-left mb-1.5 ">
                                        <span
                                            style={{backgroundColor: `${savedCategories[idx].color}`}}
                                            className={"w-5 h-5 rounded-full flex items-center justify-center cursor-pointer"}
                                            onClick={() => setSelectedCategory({title:category.title, id:category.id})}
                                        >
                                            {selectedCategory.id === category.id && (
                                                <span className="font-bold text-white text-sm">
                                                    {idx}
                                                </span>
                                            )}
                                        </span>
                                        <span className="w-full border-r-2 ml-2 text-sm text-gray-700">
                                            {category.title}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <footer className="flex justify-between border-t p-3 mt-5">
                        {!selectedEvent && (<div></div>)}   {/*empty div for alignment*/}
                        {selectedEvent && (
                            <button
                                onClick={() => {
                                    dispatchCalEvent({type: "delete", payload: selectedEvent});
                                    setShowEventModel(false);
                                }}
                                className="bg-red-400 hover:bg-red-600 w-20 py-2 rounded text-white"
                            >
                                Delete
                            </button>
                        )}
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="bg-blue-500 hover:bg-blue-600 w-20 py-2 rounded text-white"
                        >
                            Save
                        </button>
                    </footer>
                </form>
            </div>
            <div>
                <React.Fragment>
                    {showEventCopier && <EventCopier />}
                </React.Fragment>
            </div>
        </div>
    );
}