function Sidebar({
    notes,
    onAddNote,
    onDeleteNote,
    activeNote,
    setActiveNote,
    onSave
}) {
    const sortedNotes = notes.sort((a,b) => b.lastModified - a.lastModified);

    return (
        <div className="w-1/4">
            <div className="flex justify-between p-4 border-b">
                <h1 className="text-l font-bold">Scratchpad </h1>
                <div className="flex justify-between">
                    <button className="text-sm px-8" onClick={onAddNote}>Add</button>
                    <button className="text-sm" onClick={onSave}>Save</button>
                </div>
            </div>

            <div className="h-[90vh] overflow-y-scroll">
                {sortedNotes.map((note) => (
                    <div
                        className={`px-4 py-2 cursor-pointer ${note.id === activeNote && "active"}`}
                        onClick={() => setActiveNote(note.id)}
                    >
                    <div className="flex justify-between">
                        <p className="text-sm font-bold">{note.title}</p>
                        <button onClick={() => onDeleteNote(note.id)} className="text-sm text-red-500">Delete</button>
                    </div>

                    <p className="text-xs">{note.body && note.body.substr(0,100) + "..."}</p>

                    <p className="text-xs">
                        Last modified {new Date(note.lastModified).toLocaleDateString("en-GB",{
                            hour: "2-digit",
                            minute: "2-digit",
                        })}
                    </p>
                </div>
                ))}
            </div>

        </div>
    );
}

export default Sidebar;