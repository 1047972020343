import React, { useContext } from 'react'
import GlobalContext from '../context/globalcontext';

export default function CreateEventButton(){

    const { setShowEventModel } = useContext(GlobalContext)

    return(
        <button
            onClick={() => setShowEventModel(true)}
            className="border p-2 rounded-full flex items-center shadow hover:shadow-2xl"
        >
            <p>+</p>
            <span className="px-3"> Create </span>
        </button>
    );
}