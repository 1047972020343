import React, { useContext } from 'react';
import dayjs from 'dayjs';

import feyn from '../assets/feyn.png';
import GlobalContext from '../context/globalcontext';

export default function Header(){
    // const baseUrl = 'http://localhost:8383'
    const baseUrl = 'https://wtcui.ca/api';

    const {monthIndex, setMonthIndex} = useContext(GlobalContext)

    function handlePrevMonth() {
        setMonthIndex(monthIndex - 1);
    }
    function handleNextMonth() {
        setMonthIndex(monthIndex + 1);
    }
    function handleReset() {
        setMonthIndex(
            monthIndex === dayjs().month()
                ? monthIndex + Math.random() //hack: add number in (0,1), used in util.js
                : dayjs().month()
        );
    }
    async function onSave(e) {
        e.preventDefault()
        const res = await fetch(baseUrl + '/saveData',
        {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                parcel: {
                    savedNotes: localStorage.notes,
                    savedEvents: localStorage.savedEvents,
                    savedCategories: localStorage.savedCategories,
                    currentPassword: localStorage.password
                }
            })
        })
    }


    return(
        <header className="px-4 py-2 flex items-center border-b-2">
            <img src={feyn} alt="" className="mr-2 w-12 h-12" />
            <h1 className="mr-10 text-xl text-gray-500 font-bold">
                Calendar
            </h1>
            <button onClick={onSave} className="border rounded py-2 px-2 pr-5">
                Save
            </button>
            <button onClick={handleReset} className="border rounded pl-2 pr-4 pr-5">
                Today
            </button>
            <button onClick={handlePrevMonth}>
                <span className="text-l material-icons-outlined cursor-pointer text-gray-600 mx-2">
                    &lt; Prev
                </span>
            </button>
            <button onClick={handleNextMonth}>
                <span className="text-l material-icons-outlined cursor-pointer text-gray-600 mx-2">
                    Next &gt;
                </span>
            </button>
            <h2 className="ml-4 text-xl text-gray-500 font-bold">
                {dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}
            </h2>

        </header>
    )
}
