import React from 'react';

import { NavLink } from 'react-router-dom';

const Navigation = () => {
  return (
    <div>
      <div className='banner'>
        <div className='banner-col1'>
          <NavLink to='/' className='banner-text' >Home</NavLink>
        </div>
        <div className='banner-col2'>
          <NavLink to='/Hourglass/hourglass' className='banner-text' >Hourglass</NavLink>
        </div>
        <div className='banner-col2'>
          <NavLink to='/Scratchpad/scratchpad' className='banner-text' >Scratchpad</NavLink>
        </div>
        <div class="banner-col2-dropdown">
          <button className='banner-text'> Todo </button>
          <div class="banner-col2-dropdown-content">
            <NavLink to='/Todo/Calendar/calendar' className='banner-text' >Calendar</NavLink>
            <NavLink to='/Todo/Agenda/agenda' className='banner-text' >Agenda</NavLink>
            <NavLink to='/Todo/Agenda/agenda' className='banner-text' >Archive</NavLink>
          </div>
        </div>
      </div>
      <div className='spacer'></div>
    </div>
  );
}

export default Navigation;