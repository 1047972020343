import React, { useContext, useState, useEffect } from "react";
import dayjs from "dayjs";
import GlobalContext from "../context/globalcontext";
import { strToMins } from "../util"

export default function Day({day, rowIdx}){

    const { setSelectedDay ,setShowEventModel, filteredEvents, setSelectedEvent, savedCategories } = useContext(GlobalContext);
    const [dayEvents, setDayEvents] = useState([]);

    useEffect(() => {
        const events = filteredEvents.filter(
            (evt) => dayjs(evt.day).format("DD-MM-YY") === day.format("DD-MM-YY")
        );
        setDayEvents(events);
    }, [filteredEvents, day]);

    function getCurrentDayClass() {
        // if day object equal to current date, then add some css (blue circle)
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? "bg-blue-600 text-white rounded-full w-7" : "";
    }

    return(
        <div className="border border-gray-200 flex flex-col">
            <header className="flex flex-col items-center">
                <div className="border-b border-gray-200 w-full">
                    {rowIdx === 0 && (
                     <p className="text-sm mt-1 text-center"> {day.format('ddd').toUpperCase()}</p>
                    )}
                </div>
                <p className={`text-sm p-1 my-1 text-center ${getCurrentDayClass()}`}>
                    {day.format('DD')}
                </p>
            </header>
            <div
                className="flex-1 cursor-pointer z-"
                onClick={() => {
                    setSelectedDay(day);
                    setShowEventModel(true);
                }}
            >
                {dayEvents
                    .filter((event) =>
                        savedCategories.find((category) => category.id === event.category.id)
                            ? savedCategories.find((category) => category.id === event.category.id).ticked
                            : savedCategories.find((category) => category.id === 0).ticked
                    ) //filter by ticked
                    .sort((a,b) => strToMins(a.startTime) - strToMins(b.startTime)) //sort by time
                    .map((evt,idx) => (
                        <div
                            key={idx}
                            className={"flex justify-between p-0.5 mr-3 text-black text-xs rounded mb-1"}
                            style={{backgroundColor: `${
                                savedCategories.find((category) => category.id === evt.category.id)
                                ? savedCategories.find((category) => category.id === evt.category.id).color
                                : savedCategories.find((category) => category.id === 0).color
                            }99`}}
                            onClick={() => setSelectedEvent(evt)}
                        >
                            <div className="truncate">
                                {evt.title}
                            </div>
                            <div>{evt.startTime ? evt.startTime : "00:00"}</div>
                        </div>
                    ))
                }
                {/*could change height to look better*/}
                <div className="h-10"/>
            </div>
        </div>
    )
}