import React from 'react';

function Agenda() {
    return (
        <div> reeee</div>
      );
    }


export default Agenda;
