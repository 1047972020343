import React, {useState, useContext, useEffect} from 'react';

import GlobalContext from './context/globalcontext';

import {getMonth} from './util';
import Header from './components/header';
import Sidebar from './components/sidebar';
import Month from './components/month';
import EventModel from './components/eventmodel';


function Calendar() {
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const { monthIndex, showEventModel } = useContext(GlobalContext);

    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex));
    }, [monthIndex]);

    return (
        //Fragments let you group a list of children without adding extra nodes to the DOM: https://legacy.reactjs.org/docs/fragments.html
        <React.Fragment>
            {showEventModel && <EventModel />}
            
            <div className="h-screen flex flex-col">
                <Header />
                <div className="flex flex-1 overflow-y-auto">
                    <Sidebar/>
                    <Month month={currentMonth}/>
                </div>
            </div>
        </React.Fragment>
      );
    }


export default Calendar;
