import React from 'react';
import { useState, useEffect } from 'react';
import uuid from "react-uuid";

import './scratchpad.css'
import Sidebar from './sidebar';
import Main from './maintext';


function Scratchpad() {

    //load data from server
    const baseUrl = 'https://wtcui.ca/api'

    async function onLoad() {
        const res0 = await fetch(baseUrl + '/login',
        {
          method: 'POST',
          headers: {
            "Content-Type": 'application/json'
          },
          body: JSON.stringify({
            parcel: localStorage.password
          })
        })

        const res = await fetch(baseUrl + '/loadData',
        {
            method: 'GET',
        }).then((response) => response.json());
        
        localStorage.notes = res.data.savedNotes
        localStorage.savedEvents = res.data.savedEvents
        localStorage.savedCategories = res.data.savedCategories
    }
    useEffect(() => {
        let ignore = false;
        if (!ignore) {
            onLoad()
        }
        return () => { ignore = true; }
    },[]);

    //instantiate notes etc.
    const [notes, setNotes] = useState(JSON.parse(localStorage.notes));
    //const [notes, setNotes] = useState([]);
    const [activeNote, setActiveNote] = useState(false);


    useEffect(() => {
        localStorage.setItem("notes", JSON.stringify(notes));
    }, [notes]);

    //useful functions
    const onAddNote = () => {
        const newNote = {
            id: uuid(),
            title: "Untitled Note",
            body: "",
            lastModified: Date.now(),
        };

        setNotes([newNote, ...notes]);
    };

    const onUpdateNote = (updatedNote) => {
        const updatedNotesArray = notes.map((note) => {
            if(note.id === activeNote){
                return updatedNote;
            }

            return note;
        });

        setNotes(updatedNotesArray);
    };

    const onDeleteNote = (idToDelete) => {
        setNotes(notes.filter((note) => note.id !== idToDelete));
    };

    const getActiveNote = () => {
        return notes.find((note) => note.id === activeNote);
    };


    //save notes to server
    async function onSave(e) {
        e.preventDefault()
        const res = await fetch(baseUrl + '/saveData',
        {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                parcel: {
                    savedNotes: localStorage.notes,
                    savedEvents: localStorage.savedEvents,
                    savedCategories: localStorage.savedCategories,
                    currentPassword: localStorage.password
                }
            })
        })
    }

    return (
        <div className='Scratchpad'>
            <Sidebar
                notes={notes}
                onAddNote={onAddNote}
                onDeleteNote={onDeleteNote}
                activeNote={activeNote}
                setActiveNote={setActiveNote}
                onSave={onSave}
            />
            <Main activeNote={getActiveNote()} onUpdateNote={onUpdateNote} />
        </div>
      );
    }


export default Scratchpad;
