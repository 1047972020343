import dayjs from 'dayjs'

export function getMonth(month = dayjs().month()) {
    month = Math.floor(month) //hack used in ./header handleReset to small calendar motnh changes when press 'Today'

    // month from 0 to 11
    const year = dayjs().year();
    const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
    let currentMonthCount = 0 - firstDayOfTheMonth //counts day of the month

    // double array: calendar has 5 rows, 7 columns
    const daysMatrix = new Array(5).fill([]).map(() => {
        return new Array(7).fill(null).map(() => {
            // return day object for every day of month
            currentMonthCount++
            return dayjs(new Date(year, month, currentMonthCount))
        });
    });
    return daysMatrix;
}

export function strToMins(t) {
    var s = t.split(":");
    return Number(s[0]) * 60 + Number(s[1]);
}