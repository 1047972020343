// Timeline.js
import React, { useRef, useEffect, useState } from 'react';
import Timeline from 'react-visjs-timeline';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';

const TimelineComponent = ({ events, selectedDate }) => {
  
  const timelineRef = useRef(null);
  const [options, setOptions] = useState({
    width: '100%',
    height: '400px',
    stack: true,
    showCurrentTime: true,
    zoomMin: 1000 * 60 * 10, // 10 minutes
    zoomMax: 1000 * 60 * 60 * 24 * 30, // 30 days
    editable: false,
  });

  useEffect(() => {
    setOptions(prevOptions => ({
      ...prevOptions,
      start: selectedDate,
      end: new Date(new Date(selectedDate).getTime() + 24 * 60 * 60 * 1000).toISOString(),
    }));
  }, [selectedDate]);


  useEffect(() => {
    if (timelineRef.current) {
      const timeline = timelineRef.current.$el;
      //timeline.fit();
    }
  }, [events]);

  const getColorClass = (color) => {
    switch(color) {
      case 'blue':
        return 'rgba(147, 197, 253, 1)';
      case 'red':
        return 'rgba(252, 165, 165, 1)';
      case 'gray':
        return 'rgba(209, 213, 219, 1)';
      // Add more cases as needed
      default:
        return 'rgba(209, 213, 219, 1)';
    }
  };
  const colorOrder = { red: 1, blue: 2, gray: 3 };
  const sortedEvents = [...events].sort((a, b) => colorOrder[a.color] - colorOrder[b.color]);

  return (
    <div>
      <Timeline
        ref={timelineRef}

        items={sortedEvents.map((event, index) => ({
          id: index,
          content: event.name,
          start: event.start,
          end: event.end,
          style: `background-color: ${getColorClass(event.color)}; border: 1px solid ${getColorClass(event.color)}`,
          className: "text-sm h-8 px-2",
        }))}
        options={options}
      />
    </div>
  );
};

export default TimelineComponent;