import React from 'react'

import Day from './day'

export default function Month({month}){
    return(
        <div className="flex-1 grid grid-cols-7 grid-row-5 max-h-0.5">
            {month.map((row, i) => (
                // want fake element (?)
                <React.Fragment key={i}>
                    {row.map((day, idx) => (
                        <Day day={day} key={idx} rowIdx={i} />
                    ))}
                </React.Fragment>
            ))}
        </div>
    )
}