import React, { useState, useEffect, useContext } from 'react'
import GlobalContext from '../context/globalcontext';

export default function Categories() {

    const { savedCategories, dispatchCategory } = useContext(GlobalContext);


    const [selectedCategory, setSelectedCategory] = useState(null)
    const [showCategoryModel, setShowCategoryModel] = useState(false);

    useEffect(() => {
        if(!showCategoryModel) {
            setSelectedCategory(null);
        }
    }, [showCategoryModel]);
    

    function CategoryModel() {

        const [title, setTitle] = useState(selectedCategory ? selectedCategory.title : "");
        const [color, setColor] = useState(selectedCategory ? selectedCategory.color : "#0076c9")

        function handleSubmit(e) {
            e.preventDefault();
    
            const newCategory = {
                title,
                color,
                archived : false,
                ticked : true,
                id : selectedCategory ? selectedCategory.id : Date.now(),
            };
            if (selectedCategory) {
                dispatchCategory({type: "update", payload: newCategory});
            } else {
                dispatchCategory({type: "push", payload: newCategory});
            }
        
            setShowCategoryModel(false);
        }
        function handleArchive(e) {
            e.preventDefault();
            
            const newCategory = {
                title: selectedCategory.title,
                color: selectedCategory.color,
                archived : !selectedCategory.archived,
                ticked : selectedCategory.archived,
                id : selectedCategory.id,
            };
            dispatchCategory({type: "update", payload: newCategory});
        
            setShowCategoryModel(false);
        }

        return(
            <div className="fixed left-64 top-1/2 border-2 w-100 bg-white rounded-lg shadow-2xl">
                <header className="h-10 px-4 py-2 flex justify-between items-center bg-gray-100">
                    <span className="text-gray-700">
                        {!selectedCategory && (
                            <span>Add Category: </span>
                        )}
                        {selectedCategory && !selectedCategory.archived && (
                            <span>Modify Category: </span>
                        )}
                        {selectedCategory && selectedCategory.archived && (
                            <span>Archived Category: </span>
                        )}
                    </span>

                    <button
                        type="button" //to remove annoying console message
                        onClick={() => setShowCategoryModel(false)}
                    >
                        <span className="text-gray-400">
                            X
                        </span>
                    </button>
                </header>
                <div className="p-3">
                    <div className="grid grid-cols-1/5 items-end gap-y-5">
                        <span className="text-gray-400">
                            Title:
                        </span>
                        <input
                            type="text"
                            name="title"
                            placeholder="Add title"
                            value={title}
                            required
                            className="pt-0 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                            onChange={(e) => setTitle(e.target.value)}
                        />

                        <span className="text-gray-400">
                            Color:
                        </span>
                        <input
                            type="color"
                            name="color"
                            value={color}
                            className="ml-2.5"
                            onChange={(e) => setColor(e.target.value)}
                        />
                    </div>
                </div>
                <footer className="flex justify-between border-t p-3 mt-1">
                    {!selectedCategory && (<div></div>)}   {/*empty div for alignment*/}
                    {selectedCategory && !selectedCategory.archived && selectedCategory.id!==0 && (
                        <div>
                            <button
                                onClick={() => {
                                    dispatchCategory({
                                        type: "delete",
                                        payload: selectedCategory
                                    });
                                    setShowCategoryModel(false);
                                }}
                                className="bg-red-400 hover:bg-red-600 w-20 py-2 rounded text-white"
                            >
                                Delete
                            </button>
                            <button
                                onClick={handleArchive}
                                className="bg-gray-400 hover:bg-gray-500 w-20 py-2 rounded text-white ml-4"
                            >
                                
                                Archive
                            </button>
                        </div>
                    )}
                    
                    {selectedCategory && selectedCategory.archived && selectedCategory.id!==0 && (
                        <div>
                            <button
                                onClick={() => {
                                    dispatchCategory({
                                        type: "delete",
                                        payload: selectedCategory
                                    });
                                    setShowCategoryModel(false);
                                }}
                                className="bg-red-400 hover:bg-red-600 w-20 py-2 rounded text-white"
                            >
                                Delete
                            </button>
                            <button
                                onClick={handleArchive}
                                className="bg-gray-400 hover:bg-gray-500 w-20 py-2 rounded text-white ml-4"
                            >
                                Unarchive
                            </button>
                        </div>
                    )}
                    <div></div> {/*empty div for alignment*/}
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="bg-blue-500 hover:bg-blue-600 w-20 py-2 rounded text-white"
                    >
                        Save
                    </button>
                </footer>
            </div>
        )
    }
    
    return(
        <div>
            <div className="mt-10 flex justify-between">
                <span className="text-gray-500 font-bold "> Categories </span>
                <button onClick={() => {setShowCategoryModel(true)}}>
                    Add
                </button>
            </div>
            {savedCategories.map((category, idx) => (
                <div key={idx} className="flex justify-left mt-1 h-5">
                    <input
                        type="checkbox"
                        checked={category.ticked}
                        style={{backgroundColor: `${savedCategories[idx].color}`}}
                        className={`form-checkbox border-0 border-gray-900 font-rounded focus:ring-0 cursor-pointer`}
                        onChange={() =>
                            dispatchCategory({
                                type: "update",
                                payload: {
                                    title: category.title,
                                    color: category.color,
                                    archived: category.archived,
                                    ticked: !category.ticked,
                                    id: category.id,
                                }
                            })
                        }
                    />
                    <span
                        className="w-full border-r-2 ml-2 text-sm text-gray-700 cursor-pointer"
                        onClick={() => {
                            setSelectedCategory(category);
                            setShowCategoryModel(true);
                        }}
                    >
                        {category.title}
                    </span>
                </div>
            ))}

            <React.Fragment>
                {showCategoryModel && <CategoryModel />}
            </React.Fragment>
        </div>
    );
};