import React from 'react'

const GlobalContext = React.createContext({
    monthIndex: 0,
    setMonthIndex: (index) => {},

    smallCalendarMonth: 0,
    setSmallCalendarMonth: (index) => {},

    selectedDay: null,
    setSelectedDay: (day) => {},

    showEventModel: false,
    setShowEventModel: () => {},

    selectedEvent: null,
    setSelectedEvent: () => {},

    savedEvents: [],
    dispatchCalEvent: ({type, payload}) => {},

    savedCategories: [],
    dispatchCategory: ({type, payload}) => {},

    //reeeeeeeeeeeeeeeeeeeework
    labels: [],
    setLabels: () => {},
    updateLabel: () => {},

    filteredEvents: [],
});

export default GlobalContext;