import React from 'react'

import CreateEventButton from './createeventbutton';
import SmallCalendar from './smallcalendar';
import Categories from './categories';

export default function Sidebar(){
    return(
        <aside className="border p-5 w-64">
            <CreateEventButton />
            <SmallCalendar />
            <Categories />
        </aside>
    );
}