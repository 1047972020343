import React from "react";
import ReactMarkdown from "react-markdown";
//import MathJax from "react-mathjax";
//import RemarkMathPlugin from "remark-math";


function Main({activeNote, onUpdateNote}) {
    
    const onEditField = (key, value) => {
        onUpdateNote({
            ...activeNote, /*spread out all keys*/
            [key]: value, /*title or body*/
            lastModified: Date.now(),
        })
    };

    if(!activeNote) return <div className="no-active-note">No note selected. Please enter password in LocalStorage.</div>;

    return (
        <div className="w-9/12">
            <div className="h-full px-4">
                <input className="w-full my-2 border-0 border-b-4 border-blue-500 text-l font-bold"
                    type="text"
                    id="title"
                    value={activeNote.title}
                    onChange={(e) => onEditField("title", e.target.value)}
                    autoFocus
                />
                <div className="float-right w-[40vw] h-[90vh] mx-2 border-t-2 border-l-2 bg-gray-100 border-gray-400 overflow-y-scroll">
                    <ReactMarkdown className="prose text-sm max-w-none pl-4 pt-2 text-gray-900">{activeNote.body}</ReactMarkdown>
                </div>
                <div className="w-[32vw] h-full">
                    <textarea className="text-sm h-[90vh] font-mono"
                        id="body"
                        placeholder="Type here"
                        value={activeNote.body}
                        onChange={(e) => onEditField("body", e.target.value)}
                    />
                </div>

            </div>
        </div>
    );
}

export default Main;