import React, { useState, useEffect, useReducer, useMemo } from 'react';
import dayjs from 'dayjs';

import GlobalContext from './globalcontext'


function initEvents() {
    const storageEvents = localStorage.getItem("savedEvents");
    const parsedEvents = storageEvents ? JSON.parse(storageEvents) : [];
    return parsedEvents;
}
function savedEventsReducer(state, {type, payload}) {
    switch (type) {
        case "push":
            return [...state, payload];
        case "update":
            return state.map((evt) => evt.id === payload.id ? payload : evt);
        case "delete":
            return state.filter((evt) => evt.id !== payload.id);
        default:
            throw new Error();
    }
}


function initCategories() {
    const storageCategories = localStorage.getItem("savedCategories");
    const defaultCategory = {
        title: "Unclassified",
        color: "#cccccc",
        ticked: true, 
        id: 0
    }
    const parsedCategories = storageCategories ? JSON.parse(storageCategories) : [defaultCategory];
    return parsedCategories;
}
function savedCategoriesReducer(state, {type, payload}) {
    switch (type) {
        case "push":
            return [...state, payload];
        case "update":
            return state.map((evt) => evt.id === payload.id ? payload : evt);
        case "delete":
            return state.filter((evt) => evt.id !== payload.id);
        default:
            throw new Error();
    }
}


export default function CalendarContextWrapper(props){
    //load data from server
    const baseUrl = 'https://wtcui.ca/api';

    async function onLoad() {
        const res0 = await fetch(baseUrl + '/login',
        {
          method: 'POST',
          headers: {
            "Content-Type": 'application/json'
          },
          body: JSON.stringify({
            parcel: localStorage.password
          })
        })

        const res = await fetch(baseUrl + '/loadData',
        {
            method: 'GET',
        }).then((response) => response.json());
        
        localStorage.notes = res.data.savedNotes
        localStorage.savedEvents = res.data.savedEvents
        localStorage.savedCategories = res.data.savedCategories
    }
    useEffect(() => {
        let ignore = false;
        if (!ignore) {
            onLoad()
        }
        return () => { ignore = true; }
    },[]);


    const [monthIndex, setMonthIndex] = useState(dayjs().month());
    const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
    const [selectedDay, setSelectedDay] = useState(dayjs());
    const [showEventModel, setShowEventModel] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [savedEvents, dispatchCalEvent] = useReducer(savedEventsReducer, [], initEvents);
    const [savedCategories, dispatchCategory] = useReducer(savedCategoriesReducer, [], initCategories);

    useEffect(() => {
        if (smallCalendarMonth !== null) {
            setMonthIndex(smallCalendarMonth);
        }
    }, [smallCalendarMonth]);

    useEffect(() => {
        if(!showEventModel) {
            setSelectedEvent(null);
        }
    }, [showEventModel]);

    useEffect(() => {
        localStorage.setItem("savedEvents", JSON.stringify(savedEvents));
    }, [savedEvents]);

    useEffect(() => {
        localStorage.setItem("savedCategories", JSON.stringify(savedCategories));
    }, [savedCategories]);



    function updateCategoryTicked(category) {
        
    }



    //reeeeeeeeeeeework
    const [labels, setLabels] = useState([]);
    const filteredEvents = useMemo(() => {
        return savedEvents.filter(evt =>
            labels
                .filter((lbl) => lbl.checked)
                .map(lbl => lbl.label)
                .includes(evt.label)
        );
    }, [savedEvents, labels]);

    useEffect(() => {
        setLabels((prevLabels) => {
            return [...new Set(savedEvents.map(evt => evt.label))].map((label) => {
                const currentLabel = prevLabels.find((lbl) => lbl.label === label);
                return {
                    label,
                    checked: currentLabel ? currentLabel.checked : true,
                };
            });
        });
    }, [savedEvents]);


    function updateLabel(label) {
        setLabels(labels.map((lbl) => lbl.label === label.label ? label : lbl))
    };

    return(
        <GlobalContext.Provider
            value={{
                monthIndex,
                setMonthIndex,
                smallCalendarMonth,
                setSmallCalendarMonth,
                selectedDay,
                setSelectedDay,
                showEventModel,
                setShowEventModel,
                selectedEvent,
                setSelectedEvent,
                savedEvents,
                dispatchCalEvent,
                savedCategories,
                dispatchCategory,
                labels,
                setLabels,
                updateLabel,
                filteredEvents,
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    )
}
