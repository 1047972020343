import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { getMonth } from '../util';

export default function DuplicatorCalendar({duplicateDays, setDuplicateDays, currentMonthIdx, setCurrentMonthIdx}){

    const [currentMonth, setCurrentMonth] = useState(getMonth());

    useEffect(() => {
        setCurrentMonth(getMonth(currentMonthIdx));
    }, [currentMonthIdx]);

    function handlePrevMonth() {
        setCurrentMonthIdx(currentMonthIdx - 1);
    }
    function handleNextMonth() {
        setCurrentMonthIdx(currentMonthIdx + 1);
    }

    function getDayClass(day) {
        const nowDay = dayjs().format("DD-MM-YY");
        const currDay = day.format("DD-MM-YY");
        const slcDays = duplicateDays.map((selectedDay) => selectedDay.format("DD-MM-YY"))

        if (slcDays.includes(currDay)) {
            return "bg-blue-100 rounded-full text-blue-600 for-bold"
        } if(nowDay === currDay) {
            return "bg-blue-500 rounded-full text-white";
        } else {
            return "";
        }
    }

    return(
        <div className="my-5">
            <header className="flex justify-between">
                <p className="text-gray-500 font-bold">
                    {dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM YYYY")}
                </p>
                <div>
                    <button onClick={handlePrevMonth}>
                        <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                            &lt;
                        </span>
                    </button>
                    <button onClick={handleNextMonth}>
                        <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                            &gt;
                        </span>
                    </button>
                </div>
            </header>
            <div className="grid grid-cols-7 grid-rows-6">
                {currentMonth[0].map((day,i) => (
                    <span key={i} className="text-sm py-1 text-center">
                        {day.format("dd").charAt(0)}
                    </span>
                ))}
                {currentMonth.map((row, i) => (
                    <React.Fragment key={i}>
                        {row.map((day, idx) => (
                            <button
                                key={idx}
                                onClick={() => {
                                    if (duplicateDays
                                        .map((duplicateDay => duplicateDay.format("DD-MM-YY")))
                                        .includes(day.format("DD-MM-YY")))
                                    {
                                        setDuplicateDays(duplicateDays.filter(function(e) {
                                            return e.format("DD-MM-YY") !== day.format("DD-MM-YY")
                                        }));
                                    } else {
                                        setDuplicateDays([...duplicateDays, day]);
                                    };
                                }}
                                className={`py-1 w-full text-gray-600 ${getDayClass(day)}`}
                            >
                                <span className="text-sm">{day.format("D")}</span>
                            </button>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}