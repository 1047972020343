import React from 'react';

import './navigation.css';
import './background.css';
import logo from './feyn.svg';


import {HashRouter as Router, Routes, Route, Link, NavLink} from "react-router-dom";
import Navigation from './navigation';
import Calendar from './Todo/Calendar/calendar'; import CalendarContextWrapper from './Todo/Calendar/context/calendarcontextwrapper';
import Agenda from './Todo/Agenda/agenda';
import Scratchpad from './Scratchpad/scratchpad';

import Hourglass from './Hourglass/hourglass';
import HourglassNavigation from './Hourglass/navigation';
import HourglassUpdateLog from './Hourglass/updates';


import Logger from './Logger/logger';

function MatchAllRoute() {
  return <h2>The requested page does not exist.</h2>;
}

class Annals extends React.Component {
  render() {

    // initialize localStorage
    function initData(){
      if (localStorage.getItem("password") === null) {
        localStorage.password = "0"
      }
      if (localStorage.getItem("notes") === null) {
        localStorage.notes = "[]"
      }
      if (localStorage.getItem("savedEvents") === null) {
        localStorage.savedEvents = "[]"
      }
      if (localStorage.getItem("savedCategories") === null) {
        localStorage.savedCategories = "[]"
      }
    }
    initData()

    return (
      <div>
        <div>
          <Router>
            <div>
                <Navigation />
                <Routes>
                  <Route path="Todo/Calendar/calendar" element={
                    <CalendarContextWrapper>
                      <Calendar />
                    </CalendarContextWrapper>
                  }/>
                  <Route path="Todo/Agenda/agenda" element={<Agenda />} />
                  <Route path="Scratchpad/scratchpad" element={<Scratchpad />} />
                  <Route path="Hourglass/hourglass" element={<Hourglass />} />
                  <Route path="Hourglass/navigation" element={<HourglassNavigation />} />
                  <Route path="Hourglass/updates" element={<HourglassUpdateLog />} />
                  <Route path="Logger/logger" element={<Logger />} />
                  <Route path="/" element={null} />
                  <Route path="*" element={<MatchAllRoute />} />
                </Routes>
            </div>
          </Router>
        </div>
        <div>
          <header className="App-header">
            <img src={logo} className="App-logo"/>
          </header>
        </div>
      </div>
    );
  }
}

export default Annals;
