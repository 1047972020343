import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Annals from './Annals';

ReactDOM.render(
  <React.StrictMode>
      <Annals />
  </React.StrictMode>,
  document.getElementById("root")
);

